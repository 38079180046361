@import '~@vf-alchemy/vattenfall-design-system/scss/default-import';

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.9rem;
  font-weight: 400;
  color: $dark;
  text-decoration: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 10px;
  transition: background-color 0.3s;

  img {
    width: 0.9rem;
  }

  img,
  svg {
    pointer-events: none;
  }

  &:hover {
    text-decoration: none;
  }

  &:hover:not(:disabled) {
    background-color: $gray-100;
  }

  &:focus {
    outline: none;
  }

  &:disabled,
  &--disabled {
    pointer-events: none;
    cursor: auto;
    color: $gray-400;

    img,
    svg {
      filter: invert(96%) sepia(4%) saturate(408%) hue-rotate(172deg)
        brightness(96%) contrast(77%);
    }
  }

  &--blue {
    color: $secondary;

    &:hover:not(:disabled) {
      background-color: $vattenfall-light-blue;
    }

    img,
    svg {
      filter: invert(32%) sepia(90%) saturate(481%) hue-rotate(165deg)
        brightness(102%) contrast(93%);
    }
  }

  &--white {
    background-color: rgba(#fff, 0.3);
    color: #fff;

    &:hover:not(:disabled) {
      background-color: rgba(#fff, 0.5);
    }

    img,
    svg {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(34deg)
        brightness(101%) contrast(101%);
    }
  }

  &--light-grey {
    background-color: rgba($gray-200, 0.5);

    &:hover:not(:disabled) {
      background-color: $gray-200;
    }
  }

  &--red {
    color: lighten($vattenfall-dark-red, 10);

    &:hover:not(:disabled) {
      background-color: $vattenfall-light-red;
    }

    img,
    svg {
      filter: invert(27%) sepia(99%) saturate(1845%) hue-rotate(351deg)
        brightness(98%) contrast(100%);
    }
  }
}

.animated {
  & svg {
    animation: rotating 3s linear infinite;
  }
}

.animated-hover:hover {
  & svg {
    animation: rotating 3s linear infinite;
  }
}

.animated-opacity {
  & svg {
    animation: blink-animation 1s steps(5, start) infinite;
  }
}

@keyframes blink-animation {
  to {
    opacity: 0;
  }
}
