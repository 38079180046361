@import "~@vf-alchemy/vattenfall-design-system/scss/default-import";

.kpis {
  width: 400px;
  max-width: 100%;

  &__item {
    padding: .5rem 1rem;
    border: 1px solid $gray-200;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .5rem;
    line-height: 20px;


    &__label {
      font-Size: .85rem; 
      color: $gray-400; 
    }

    &__ctas {
      display: flex;
    }
  }
}