.kanban-board__search-form__ctas {
  display: flex;
  justify-content: space-between;

  &:has(form) {
    & form {
      width: 100%;
      display: flex;
      gap: 5px;

      .vf-input-container {
        margin-right: auto;
      }
    }
    & > button {
      display: none;
    }
  }
}

.kanban-board__filters {
  .tags_search_type {
    position: static;
    margin-bottom: -46px;
    margin-top: 1rem;
  }

  .vf-tooltip--bottom {
    .vf-tooltip-message {
      right: 0%;
      transform: translate(0%, 100%);

      &:before {
        left: auto;
        right: 10px;
        margin: 0;
      }

      &:after {
        left: auto;
        right: 10px;
        margin: 0;
      }
    }
  }
}

.search-form__container .search-form__input {
  padding-top: 12px;
  padding-bottom: 12px;
}
