.notification {
  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    align-content: stretch;

    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #f1f1f1;

    &-image {
      margin-right: 20px;
      height: 44px;
      width: 44px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    &-event {
      color: #222;
      font-size: 1rem;
      font-weight: 500;
      letter-spacing: -0.05px;
      line-height: 28px;
    }

    &-time {
      color: #888;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 24px;
      font-size: 0.875rem;
      padding-top: 1px;
    }
  }
}
