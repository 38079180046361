@import "~@vf-alchemy/vattenfall-design-system/scss/default-import";

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba($gray-600, 0.12), 0 1px 2px rgba($gray-600, 0.24);
  transition: all 0.3s ease-out;

  &:hover {
    box-shadow: 0 8px 16px rgba($gray-600, 0.25),
      0 6px 8px rgba($gray-600, 0.22);
  }

  a {
    display: contents;
  }

  a:hover {
    text-decoration: none;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: 0.75rem 1rem;

    &__item {
      & > button,
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.5rem;
        width: 2rem;
        padding: 0;
        border: none;
        border-radius: 6px;
        background-color: transparent;
        transition: background-color 0.3s ease-out;
        cursor: pointer;

        &:hover,
        &:focus {
          background-color: $gray-100;
          outline: none;
        }
      }
    }

    &--bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: right;
    }
  }

  &__image {
    min-height: calc(
      100% - 180px
    ); // 180px - header (56px) + footer (56px) + content (68px)
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__content {
    color: $dark;
    flex-grow: 1;
    padding: 0 1rem;

    &__title {
      margin: 0.5rem 0;
      font-size: 1.2rem;
      line-height: 1.5;
      max-height: 3em;
      overflow: hidden;
    }

    &__body {
      color: $gray-700;
      font-size: 0.9rem;
      margin-bottom: 0;
      line-height: 1.5rem;
      max-height: 4.75em;
      overflow: hidden;
    }
  }

  &__characteristics {
    color: $gray-500;
    padding: 0.5rem 1rem;

    > * {
      font-size: 0.8rem;
      font-weight: 400;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }

    img {
      height: 0.8rem;
      margin-right: 0.25rem;
      filter: invert(77%) sepia(13%) saturate(183%) hue-rotate(169deg)
        brightness(93%) contrast(85%);
    }
  }
}
