.overview {
  .intro {
    ul,
    ol {
      text-align: left;
    }
    blockquote {
      position: relative;
      padding: 36px;
      border-radius: 20px;
      margin: 40px 0 60px;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: -0.1px;
      background-color: #faf9dc;
      & > * {
        margin: 0;
        padding: 0;
      }
    }
  }
}
