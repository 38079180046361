@import '~@vf-alchemy/vattenfall-design-system/scss/default-import';

.table-item {
  border: 1px solid $gray-200;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  height: 100%;

  &:hover {
    .table-item__more-info {
      max-height: 200px;
      transition-delay: 1s;
    }
  }

  a {
    color: $dark;
    font-weight: 400;

    &:hover {
      text-decoration: none;
    }
  }

  &__title {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 1.1rem;

    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__characteristics {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(8, 1fr) auto;
    font-size: 0.95rem;

    &__header {
      font-size: 0.85rem;
      color: $gray-400;
      margin-bottom: 5px;
      white-space: nowrap;
    }

    &__status {
      text-transform: uppercase;
    }

    &__tags {
      .idea-tags__item {
        padding: 5px;
        font-size: 0.625rem;
        line-height: 0.875rem;
      }
    }
  }

  &__more-info {
    max-height: 0;
    overflow: hidden;
    transition: 0.5s max-height ease-out;
  }
}
