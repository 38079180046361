.no-page__container{
    text-align: center;
    &__image{
        height: '40vw';
        max-height: 280px;
    }
}

.no-page__row{
    flex-direction: row-reverse;
}

@media all and (min-width: 580px){
    .no-page__container{
        text-align: left;
    }   
}