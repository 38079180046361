.color-picker {
  position: relative;

  &__selected-color {
    position: relative;
    border-radius: 4px;
    height: 25px;
    width: 25px;
    margin: 0 01rem;
    cursor: pointer;

    &::after {
      position: absolute;
      content: '▼';
      right: -14px;
      top: 2px;
      font-size: 0.8rem;
    }
  }

  &:has(.color-picker__popover) {
    .color-picker__selected-color::after {
      content: '▲';
    }
  }

  &__popover {
    position: absolute;
    background-color: white;
    top: 100%;
    left: 0;
    padding: 0.5rem 1rem;
    border: 1px solid #ccc;
    width: 240px;
    z-index: 100;

    &__list {
      margin-top: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      gap: 4px;

      &__option {
        label {
          height: 25px;
          width: 25px;
          padding: 2px;
          border: 2px solid;
          border-radius: 4px;
          cursor: pointer;
        }

        input {
          display: none;
        }

        &--selected label {
          border-color: #000 !important;
        }
      }
    }
  }
}
