@import "~@vf-alchemy/vattenfall-design-system/scss/default-import";

.checkbox__container {
  display: grid;
  grid-template-columns: auto 1fr;
}

// @alchemy styles used in drop-down checkbox inputs 
input[type='checkbox'] {
  appearance: none;
  background: rgb(255, 255, 255);
  border-radius: 4px 4px 4px 4px;
  border: 1px solid rgb(164, 169, 172);
  height: 28px;
  width: 28px;
  line-height: 1;
  position: relative;
  vertical-align: middle;
  margin-right: 12px;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    transition: transform .2s ease-in-out;
    border-bottom: 3px solid rgb(216, 216, 216);
    border-right: 3px solid rgb(216, 216, 216);
    height: 16px;
    width: 8px;
    margin: auto;
    left: 9px;
    top: 3px;
    transform: rotate(45deg) scale(0);
  }

  &:checked {
    &:before {
      transform: rotate(45deg) scale(1);
      border-bottom: 3px solid rgb(78, 75, 72);
      border-right: 3px solid rgb(78, 75, 72);
    }
  }

  &:hover {
    &:before {
      transform: rotate(45deg) scale(1);
    }
  }

  &:disabled {
    background: rgb(241, 241, 241);

    &:before {
      transform: rotate(45deg) scale(0);
    }

    &.pre-selected {
      &:before {
        transform: rotate(45deg) scale(1);
        border-bottom: 3px solid rgb(216, 216, 216);
        border-right: 3px solid rgb(216, 216, 216);
      }
    }
  }

  &:focus {
    outline: none;
  }
}

input[type="checkbox"] {
  &+label {
    cursor: pointer;
    opacity: 0.7;
    transition: opacity .2s ease-in-out;
  }

  &:checked {
    &+label {
      opacity: 1;
    }
  }
}