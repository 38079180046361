.admin__links {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;

  &__item {
    width: 240px;
    padding: 1rem;

    .card__content__title {
      text-align: center;
    }

    .card__image {
      text-align: center;

      svg {
        height: 120px;
      }
    }
  }
}
