@import '~@vf-alchemy/vattenfall-design-system/scss/default-import';

.idea-tags {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 5px;

  &__item {
    background-color: #e6e6e6;
    padding: 0px 8px;
    border-radius: 2px;
    font-size: 0.9rem;
  }
}

.tags_search_type {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 1rem;
  right: 12px; // 12px is the padding of the parent container

  &__label {
    font-size: 0.8rem;
  }

  .switch_buttons__item {
    width: auto !important;
    height: auto !important;

    label {
      font-size: 0.8rem;
    }
  }
}
