@import '~@vf-alchemy/vattenfall-design-system/scss/default-import';

.inline-list {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: flex-start;

  &__item {
    padding: 0.5rem 1rem;
    border: 1px solid $gray-200;
    border-radius: 5px;
    display: flex;
    gap: 1.5rem;
    align-items: center;

    &__ctas {
      display: flex;
    }

    &--inactive {
      background-color: $gray-100;
      opacity: 0.5;
    }
  }

  &__form {
    input {
      border: none;
      border-bottom: 1px solid $gray-300;
      padding: 4px 0;

      &:focus {
        outline: none;
      }
    }

    .vf-input {
      border-color: #e8e8e9; // other fields have this color
      padding: 4px 8px;
      font-size: 1rem;
    }

    label {
      margin: 0 !important;
      font-size: 0.9rem;
    }

    &__ctas {
      display: inline-flex;
    }
  }
}
