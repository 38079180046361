@import '~@vf-alchemy/vattenfall-design-system/scss/default-import';

.switch_buttons {
  display: flex;
  justify-content: space-around;
  gap: 5px;
  margin: 0 !important;

  &__item {
    width: 80px;
    height: 35px;
    font-size: 0.9rem;
    display: flex;
    text-align: center;
    align-items: center;
    border: 1px solid $gray-200;
    border-radius: 4px;
    transition: all 0.3s ease-out;
    font-weight: 400;
    cursor: pointer;
    position: relative;

    &:hover {
      border-color: $gray-500;
      color: black;
    }

    &.active {
      background-color: #edf1f6;
      border-color: #1964a3;
    }

    input[type='radio'] {
      visibility: hidden;
      position: absolute;
    }

    label {
      cursor: pointer;
      margin-bottom: 0;
      width: 100%;
      padding: 0.25rem 0.6rem;
    }
  }
}
