.footer__languages__container {
  margin-top: 10px;
}

.footer__languages__button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: .5rem 0;
  font-size: 18px;
  font-weight: 500;

  &:focus {
    outline: none;
  }
}