.slide-panel {
  position: fixed;
  background: #fff;
  padding: 20px;
  transition: all 0.5s;
  z-index: 9990;
  overflow-y: auto;

  &__close {
    text-align: right;
    margin-bottom: 1rem;

    button {
      cursor: pointer;
      background-color: transparent;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }
}
