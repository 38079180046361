@import "~@vf-alchemy/vattenfall-design-system/scss/default-import";

$emptyStar: '☆';
$solidStar: '★';

textarea {
    width: 100%;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid $gray-400;
    padding: 16px 20px;
    color: $dark;
}

.idea-details__rates {
    display: flex;
    align-items: center;
    font-size: 1rem;

    &__likes {
        display: flex;
        align-items: center;

        &__heart {
            font-size: 1.8rem;
            cursor: pointer;
            margin-right: 10px;
            color: $gray-400;
            transition: color .2s ease-in-out;

            &--liked {
                color: $secondary;
            }
        }
    }

    &__stars {
        display: flex;
        direction: rtl;
        unicode-bidi: bidi-override;
        user-select: none;

        input[type="radio"] {
            display: none;

            &:checked {
                &~label:before {
                    content: $solidStar;
                    color: $secondary;
                }
            }
        }

        label {
            margin-bottom: 0;

            &:before {
                content: $solidStar;
                font-size: 1.8rem;
                cursor: pointer;
                color: $gray-400;
                transition: color .2s ease-in-out;
            }

            &:hover:before,
            &:hover~label:before {
                color: $secondary;
                content: $solidStar;
            }
        }
    }
}

.idea-details__comment {
    &__top {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        
        &__date {
            font-weight: 300;
            color: $gray-500;
        }
    }

    &__content {
        font-size: 1rem;
        line-height: 28px;
        white-space: pre-wrap;

        &__mentioned_user {
            color: lighten($vattenfall-mid-blue, 20);
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        margin-top: -5px;

        button:focus {
            outline: none;
        }
    }

    .idea-details__comment {
        margin-left: 2rem;
        border-bottom: none;

        .idea-details__comment {
            margin-left: calc(2 * 20px);
            border-bottom: none;
        }
    }
}

.idea-details__decision {
    p {
        font-size: 16px;
        line-height: 28px;
    }
}

.idea-details__top {
    align-items: flex-start;

    &__buttons {
        display: flex;
        justify-content: flex-start;
    }
}

.idea-details__action-button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;

    &:focus {
        outline: none;
    }

    &~* {
        // all but not first
        margin-left: 15px;
    }
}