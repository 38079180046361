.dropzone {
  width: 100%;
  border: 2px dashed #333;
  height: auto;
  padding: 20px;
  border-radius: 4px;
  cursor: pointer;
}

.dropzone.disabled {
  background-color: #f1f1f1 !important;
  cursor: default;
}
