@import '~@vf-alchemy/vattenfall-design-system/scss/default-import';

.lead-time {
  text-align: end;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  & > span {
    border: 1px solid #1964a3;
    padding: 6px 12px;
    background-color: #1964a3;
  }
}
