@import '~@vf-alchemy/vattenfall-design-system/scss/default-import';

$avatar-size: 32px;

.kanban-board {
  padding: 0 20px;
  &__header {
    border-bottom: 1px solid var(--Teritiary-Colours-Medium-Grey, #999);
    padding: 0 10px;
    color: #000;

    /* Desktop/Text/Body text small – Desktop */
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.75; /* 175% */

    text-transform: uppercase;
    position: sticky;
    top: 0;
    background: var(--white, #fff);
    z-index: 99;
    opacity: 0.9;
  }

  &__item {
    position: relative;
    background: var(--white, #fff);

    border-radius: 5px;
    border: 1px solid var(--Teritiary-Colours-Light-Grey, #ccc);
    padding: 10px;

    &:hover {
      .edit-button__icon {
        opacity: 1;
      }
    }

    &:not(:first-child) {
      margin-top: 10px;
    }

    &__top {
      display: flex;
      justify-content: space-between;
      align-items: top;
      flex-direction: row;
    }
    &__top-wrapper {
      width: calc(100% - #{$avatar-size} - 5px); // 5px - margin
    }
    &__title {
      color: #000;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.25; /* 125% */
      margin: 0;
    }

    &__deadline {
      font-size: 0.75rem;
      margin: 0;
    }

    &__profile-container {
      // background-color: #e4e4e4;
      border-radius: 50%;
      overflow: hidden;
      width: $avatar-size;
      height: $avatar-size;
      text-align: center;
      z-index: 20;
    }

    &__rating {
      height: 30px;
      display: flex;
      // justify-content: space-between;

      & .edit-button__icon {
        height: 30px;
        width: 30px;
        margin-left: 25px;
        z-index: 15; // To be on top of the anchor
        opacity: 0;
        transition: opacity 0.3s ease;
        img {
          padding: 7px;
          vertical-align: bottom;
        }
      }
    }

    &__tags {
      .idea-tags__item {
        // Layout
        padding: 5px;
        // Typography
        color: var(--Primary-Colours-Magnetic-Grey, #4e4848);
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.875rem; /* 140% */
        // Style
        border-radius: 3px;
        background: var(--Background-Colours-Light-Grey, #f2f2f2);
      }
    }

    &__activities {
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 300;
      line-height: 1.25; /* 125% */
      margin-top: 5px;
      width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 1.25rem;
      z-index: 99;
      position: relative;

      &:hover {
        overflow: visible;
        white-space: normal;
        height: auto;
        width: 100%;
      }
    }

    &__link {
      // border: 1px solid var(--Primary-Colours-Magnetic-Grey, #4e4848);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      transition-delay: 1s;
    }
  }

  &__list-container {
    height: 100%;

    &.dnd__is-over {
      border: 1px dashed #ccc;
      border-radius: 5px;

      margin: -5px;
      padding: 5px;
    }
  }
}
