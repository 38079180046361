.list-container {
  display: grid;
  gap: 10px;

  .header-list {
    display: grid;
    gap: 10px;
    text-transform: uppercase;
    color: #fff;

    & > div {
      background-color: #59a3e2;
      text-align: center;
      padding: 10px;
      border-radius: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &--sortable {
      cursor: pointer;

      .order-icon {
        margin-left: 5px;
      }

      &:hover {
        .order-icon {
          visibility: visible !important;
        }
      }
    }
  }

  .list-item {
    display: grid;
    gap: 10px;

    &:hover {
      background-color: #f8f9fa;
    }

    &:has(.disabled) {
      opacity: 0.4;
    }

    &-cell {
      padding: 0 10px;
      border-radius: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:has(button) {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.expert-user {
        text-align: center;
      }

      &.active,
      &.disabled {
        text-transform: uppercase;
        text-align: center;
      }
    }
  }
}
