.search-box {
  &__container {
    display: flex;
    margin-right: 20px;
  }

  &__wrapper {
    position: relative;
  }

  &__input {
    color: #222;
    border: 1px solid #e8e8e9;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-top-left-radius: 4px;
    padding: 0 8px;
    height: 50px;
    outline: none;
    &::placeholder {
      color: #adb5bd;
    }
  }

  &__clear-btn {
    height: 100%;
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #adb5bd;
  }

  &__search-btn {
    button {
      color: #222;
      cursor: pointer;
      background-color: #fff;
      border: 1px solid #e8e8e9;
      border-left-color: transparent;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      border-top-left-radius: 0px;
      height: 50px;
      padding: 0 1rem;

      &:focus {
        outline: none;
      }
    }
    img {
      height: 0.85rem;
    }
  }
}
