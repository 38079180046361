.vf-input-container:has(textarea) {
  textarea.vf_textarea_input {
    padding-bottom: 3rem;
  }
}

.textarea__counter {
  font-size: 0.75rem;
  line-height: 1rem;
  display: block;
  position: absolute;
  right: 1rem;
  bottom: 0.5rem;
  color: #adb5bd;
  text-align: right;

  &.warning {
    color: red;
  }
}
