.vf-tooltip {
  .vf-tooltip-message {
    text-align: left;
    transition: opacity 0.3s;
  }

  &:hover {
    text-decoration: none;

    .vf-tooltip-message {
      opacity: 1;
    }
  }
}

// fix for branding styles
.vf-tooltip--left ~ .vf-tooltip-message:after,
.vf-tooltip--left .vf-tooltip-message:after,
.vf-tooltip--right ~ .vf-tooltip-message:after,
.vf-tooltip--right .vf-tooltip-message:after {
  top: calc(50% - 15px);
}
