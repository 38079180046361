.ideas-list {
  &__title {
    align-items: flex-end;
  }

  &__item {
    position: relative;
    border-left: 6px solid #eee;

    &__top-info {
      font-size: 14px;
      line-height: 22px;
      text-transform: uppercase;
    }

    &__description {
      font-size: 18px;
    }

    &__state {
      font-size: 14px;
    }
  }
}

.idea-opinions {
  span {
    font-size: 14px;

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}

.idea-card__author {
  font-size: 14px;
}

.switch-layout {
  &__container {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eee;
    border: none;
    height: 40px;
    width: 40px;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 5px;
    }

    &:focus {
      outline: none;
    }

    &--active {
      background: #ccc;
    }

    img {
      width: 24px;
    }
  }
}

.edit-button {
  &__container {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  &__icon {
    background: #fff;
    opacity: 0.9;
    border: 1px solid #ccc;
    border-radius: 50%;
    height: 50px;
    width: 50px;

    &:hover {
      cursor: pointer;
    }

    img {
      opacity: 0.3;
      width: 100%;
      transition: all 0.7s;
      transform: none !important;
      aspect-ratio: 1;
      padding: 12px;

      &:hover {
        opacity: 1;
        transition: all 0.7s;
        transform: none !important;
      }
    }
  }
}