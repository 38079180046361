.search-bar {
  display: flex;
  padding: 10px;
  align-items: center;
  background: var(--Background-Colours-Light-Green, #edf9f3);
  gap: 20px;

  & > *:last-child {
    margin-left: auto;
  }

  .bookmark {
    &:not(:first-child) {
      margin-left: 10px;
    }
    display: inline-flex;
    width: 161px;
    height: 30px;
    padding: 0px 10px 0 10px;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    border: none;
    border-radius: 8px;
    background: var(--Energy-Source-Colours-Hydro, #2da55d);
    color: var(--Primary-Colours-Aura-White, #fff);
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    cursor: pointer;

    &:hover,
    &.active {
      opacity: 0.6;
    }

    &:active {
      opacity: 0.3;
      box-shadow: inset 0px 0px 3px 0px rgba(0, 0, 0, 1);
    }

    &:focus,
    &:active,
    &:focus-visible {
      outline: none;
    }

    .bookmark__text {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__delete-btn {
      display: inline-block;
      width: 24px;
      height: 24px;
      padding: 3px;
      cursor: pointer;
      border-radius: 2px;

      &:hover {
        background: var(--Primary-Colours-Aura-White, #54bf7f);
      }

      svg {
        fill: #fff;
        line-height: 1;
      }
    }
  }
}
