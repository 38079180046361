@import "~@vf-alchemy/vattenfall-design-system/scss/default-import";

.dashboard-table {
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 6px;

  span {
    border-radius: 5px;
  }

  &__header {
    display: contents;

    .cell {
      span {
        color: white;
        text-align: center;
        display: block;
        background-color: lighten($secondary, 20);
        padding: 10px;
      }
    }
  }

  &__body {
    display: contents;

    .cell {
      text-align: right;

      span {
        height: 100%;
        display: block;
        color: black;
        background-color: $gray-100;
        border: 1px solid $gray-100;
        padding: 6px 15px;
      }

      &__name {
        font-weight: 500;
        text-align: left;

        &--clickable {
          cursor: pointer;
        }
      }
    }

    .child {
      .cell {
        span {
          background-color: white;
          border: 1px solid $gray-200;
        }
      }
    }

    &__row--total {
      .cell span {
        background-color: darken($gray-100, 5);
        font-weight: 500;
      }
    }
  }

  .arrow {
    margin-top: 9px;
    float: right;
    border: solid black;
    border-width: 0 2px 2px 0;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transition: transform .1s;

    &--rotated {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
}