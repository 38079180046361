@import "~@vf-alchemy/vattenfall-design-system/scss/default-import";

.comment-input-wrapper {
  border: 1px solid #ced4da;
  border-radius: 4px;
  position: relative;
  padding: 16px 20px;

  
  textarea {
    border: none;
    appearance: none;
    box-shadow: none;
    width: 100%;
    transition: all 0.2s ease;
    font-size: 1rem;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.comment-input {
  min-height: 140px;

  .comment-input__suggestions {
    position: absolute;
    left: 1rem;
    top: 2rem;
    border: 1px solid $gray-200;
    max-height: 400px;
    overflow-y: auto;

    .comment-input__suggestions__list {
      padding: 1rem 0rem;
    }

    .comment-input__suggestions__item {
      padding: 0.5rem 1rem;
    }

    .comment-input__suggestions__item--focused {
      background-color: $gray-200;
    }
  }

  .comment-input__highlighter {
    .comment-input__mentioned-user {
      background-color: lighten($vattenfall-mid-blue, 50);
      padding: 2px 0;
    }
  }
}