.modal {
  &__container {
    span.title-name {
      font-weight: 600;
    }
  }
  &__comment-wrapper {
    position: relative;

    .textarea__counter {
      bottom: 1.5rem;
    }
    .error {
      color: red;
      display: block;
      margin-bottom: 5px;
      height: 14px;
    }
  }
}
