@import '~@vf-alchemy/vattenfall-design-system/scss/default-import';

.overview-page {
  .hero {
    &.no-video {
      @include media-breakpoint-up(lg) {
        height: 560px !important;
      }
    }
  }
}

.hero {
  &__video-container {
    width: 100%;
    height: 100%;
    border: 1px solid #fff;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: none;

      &:focus {
        border: none;
        outline: none;
      }

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }

  &__title {
    font-size: 2.5rem;

    @include media-breakpoint-up(lg) {
      font-size: 3.6rem;
    }

    @include media-breakpoint-up(xl) {
      max-width: 1000px;
      font-size: 4rem;
    }
  }
}
