.editPageLink {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  display: block;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  opacity: 0.9;
  img {
    width: 100%;
    padding: 15px;
    opacity: 0.3;
    transition: all 0.7s;
    &:hover {
      opacity: 1;
    }
  }
}
